.inputTableTablet th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: x-small;
  width: 40%;
  padding: 1%;
}

.inputTableTablet {
  width: 100%;
}
.inputTableTablet td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 60%;
  padding: 1%;
  font-size: x-small;
}
.inputTableTablet2 th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 40%;
  padding: 1%;
  font-size: x-small;
}

.inputTableTablet2 {
  width: 100%;
}
.inputTableTablet2 td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 60%;
  padding: 1%;
  font-size: x-small;
}
.inputTableTablet3 {
  width: 100%;
}
.inputTableTablet3 th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 50%;
  padding: 1%;
  font-size: x-small;
}
.inputTableTablet3 td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 50%;
  padding: 1%;
}
/* 창고료 조회 테이블 */
.inputTableTabletInquery th {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: x-small;
  width: 30%;
  padding: 1%;
}

.inputTableTabletInquery {
  width: 100%;
}
.inputTableTabletInquery td {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 1%;
}
.inputTableTabletInquery2 th {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 30%;
  padding: 1%;
  font-size: x-small;
}

.inputTableTabletInquery2 {
  width: 100%;
}
.inputTableTabletInquery2 td {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 1%;
}
.inputTableTabletInquery3 {
  width: 100%;
}
.inputTableTabletInquery3 th {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 50%;
  padding: 1%;
  font-size: x-small;
}
.inputTableTabletInquery3 td {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 50%;
  padding: 1%;
}
