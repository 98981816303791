.tableGroup1 tbody td {
  border: none;
  padding: 1.3%;
}

.tableGroup1-tablet tbody td {
  border: none;
  padding: 1.3%;
  font-size: 13px;
}

.tableGroup2 tbody td {
  vertical-align: baseline;
}

.tableGroup3 tbody td {
  border: none;
  vertical-align: baseline;
}

#mawbText{
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.date input{
  padding-top: 1vh;
  padding-bottom: 1vh;
}

#select-content {
  padding-top: 0;
  padding-bottom: 0;
}

#fileList span {
  font-size: 15px;
}


.agencyName-tablet input,
.fltDate-tablet input,
.phone-tablet input,
.email-tablet input {
  font-size: 13px;
}

