.inputTable th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: medium;
  width: 30%;
  padding: 1%;
}

.inputTable {
  width: 100%;
}
.inputTable td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 1%;
  font-weight: bold;
  text-align: center;
}
.inputTable2 th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 30%;
  padding: 1%;
  font-size: medium;
}

.inputTable2 {
  width: 100%;
}
.inputTable2 td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 1%;
  font-weight: bold;
  text-align: center;
}
.inputTable3 {
  width: 100%;
}
.inputTable3 th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 45%;
  padding: 1%;
  font-size: medium;
}
.inputTable3 td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 55%;
  padding: 1%;
}
/* 창고료 조회 테이블 */
.inputTableInquery th {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: medium;
  width: 30%;
  padding: 1%;
}

.inputTableInquery {
  width: 100%;
}
.inputTableInquery td {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 1%;
}
.inputTableInquery2 th {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 30%;
  padding: 1%;
  font-size: medium;
}

.inputTableInquery2 {
  width: 100%;
}
.inputTableInquery2 td {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 1%;
}
.inputTableInquery3 {
  width: 100%;
}
.inputTableInquery3 th {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 45%;
  padding: 1%;
  font-size: medium;
}
.inputTableInquery3 td {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 55%;
  padding: 1%;
}
.inputTableInquery-tablet th {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 13px;
  width: 30%;
  padding: 1%;
}
.inputTableInquery-tablet {
  width: 100%;
}
.inputTableInquery-tablet td {
  border: 3px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 1%;
}