.file-table thead th {
  padding: 9px 8px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 15px;
  border: none;
}

.file-table thead {
  background-color: #063B6D22;
}
.file-table tbody td {
  padding: 2px 2px;
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 13px;
}
