/* 애니메이션을 정의합니다. */
@keyframes slideUp {
  from {
    transform: translateY(-1000%);
  }
  to {
    transform: translateY(0);
  }
}

/* 컴포넌트에 애니메이션을 적용합니다. */
.slide-up {
  animation: slideUp 2s forwards;
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-1000%);
  }
}

/* 컴포넌트에 애니메이션을 적용합니다. */
.slide-down {
  animation: slideDown 1s forwards;
}

/* 애니메이션을 정의합니다. */
@keyframes slideLeft {
  from {
    transform: translateX(-300%);
  }
  to {
    transform: translateX(0);
  }
}

/* 컴포넌트에 애니메이션을 적용합니다. */
.slide-left {
  animation: slideLeft 2s forwards;
}

@keyframes slideRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-300%);
  }
}

/* 컴포넌트에 애니메이션을 적용합니다. */
.slide-right {
  animation: slideRight 1s forwards;
}
