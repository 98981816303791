.inputTableMobile th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: xx-small;
  width: 45%;
  padding: 1%;
}

.inputTableMobile {
  width: 100%;
}
.inputTableMobile td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 55%;
  padding: 1%;
  font-size: xx-small;
}
.inputTableMobile2 th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 45%;
  padding: 1%;
  font-size: xx-small;
}

.inputTableMobile2 {
  width: 100%;
}
.inputTableMobile2 td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 55%;
  padding: 1%;
  font-size: xx-small;
}
.inputTableMobile3 {
  width: 100%;
}
.inputTableMobile3 th {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 60%;
  padding: 1%;
  font-size: xx-small;
}
.inputTableMobile3 td {
  border: 3px solid #0073cf;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 40%;
  padding: 1%;
}
/* 창고료 조회 테이블 */

.inputTableMobileInquery {
  width: 100%;
}
.inputTableMobileInquery th {
  border: 2px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 10px;
  width: 30%;
  padding: 0.5%;
}
.inputTableMobileInquery td {
  border: 2px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 0.5%;
}
.inputTableMobileInquery2 th {
  border: 2px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 30%;
  padding: 0.5%;
  font-size: 10px;
}

.inputTableMobileInquery2 {
  width: 100%;
}
.inputTableMobileInquery2 td {
  border: 2px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 0.5%;
}
.inputTableMobileInquery3 {
  width: 100%;
}
.inputTableMobileInquery3 th {
  border: 2px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 30%;
  padding: 0.5%;
  font-size: 10px;
}
.inputTableMobileInquery3 td {
  border: 2px solid #00000090;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 70%;
  padding: 0.5%;
}
