.comboTable th {
  border: initial;  
  padding: 8px;
  text-align: center;
  font-size: 14px;  
  font-weight: 500;
  color: #FFF;
  font-family: "Noto Sans KR", sans-serif;
  width: initial;
}

.comboTable td {
  border: initial;  
  border-bottom: 1px solid #ccc;
  padding: initial;
  padding: 8px;
  text-align: center;
  word-wrap: break-word;
  font-size: 14px;  
  font-family: "Noto Sans KR", sans-serif;
  width: initial;
}